var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_signin_welcome") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [_c("Batching")],
        1
      ),
      _vm.hasPrivilegeSO
        ? _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [_c("CreditApproval")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }