

















import { IAuthorities } from "@interface/auth.interface";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Dashboard",
  components: {
    Batching: () => import(/*webpackPrefetch: true */"./batching/Batching.vue"),
    CreditApproval: () => import(/*webpackPrefetch: true */"./credit-approval/CreditApproval.vue"),
  },
  computed: {
    ...mapGetters([
      "authStore/GET_USER_PRIVILEGES"
    ]),
    hasPrivilegeSO(): boolean {
      return !!this["authStore/GET_USER_PRIVILEGES"].find((x: IAuthorities) => x.key === "sales-order" && x.privilege.read);
    },
  },
});
